<template>
    <div class="book-flex">
        <div class="card">
            <figure class="book card-body">
                <img src="../assets/myLeadershipBook.jpeg" class="highlight" alt="Dr. Stickly" />
                <br>
                <figcaption>
                    <p class="highlight-caption">Imagine 100 per cent of your core business activities coming from
                        referrals. No more “cold calling” and plying the market for new business! This is the kind of
                        success strategic networking brings. Networks can develop in a variety of ways and most people
                        put some thought into the connections they need in order to succeed.</p>
                    <button type="button" class="btn btn-dark"><a
                            href="https://www.smashwords.com/books/view/317956">Surgical Networking</a></button>
                </figcaption>
            </figure>
        </div>
        <div class="card">
            <figure class="book card-body">
                <img src="../assets/communicationBook.jpeg" class="highlight" alt="Dr. Stickly" />
                <br>
                <figcaption>
                    <p class="highlight-caption">
                        The “Magnificent Seven” are seven power communication techniques that while simple in message
                        and concept have far reaching use and impact. Why seven? Because that is all you need to be
                        effective in all aspects of your communication. They are simply magnificent!</p>
                    <button type="button" class="btn btn-dark"><a
                            href="https://www.smashwords.com/books/view/301243">Communication Techniques</a></button>
                </figcaption>
            </figure>
        </div>
        <div class="card">
            <figure class="book card-body">
                <img src="../assets/sustainedExcellence.jpeg" class="highlight" alt="Dr. Stickly" />
                <br>
                <figcaption>
                    <p class="highlight-caption">These techniques are definite game-changers that are worthy of sharing.
                        Each one is so useful and very powerful in key situations. Once you get going on them, it will
                        surprise you when you start to reflect on the number of times in your everyday life that you
                        have the opportunity to use them. And when you do start to use them on a regular basis, the
                        results will astonish you! These really Work!</p>
                    <button type="button" class="btn btn-dark"><a
                            href="https://www.smashwords.com/books/view/301243">Sustained Excellence</a></button>
                </figcaption>
            </figure>
        </div>
        <div class="card">
            <figure class="book card-body">
                <img src="../assets/goldendozen.jpg" class="highlight" alt="Golden Dozen" />
                <br>
                <figcaption>
                    <div>
                        <p>A Game Changer for you!</p>
                        <p class="highlight-caption">Finally, a simple summary of Rules in Business that really work!
                            From formulas that take minutes to use and accurately forecast potential business revenues
                            to common sense rules to ensure business sustainability to a rule regarding business
                            partnerships, these really are game changers!</p>
                    </div>
                    <button type="button" class="btn btn-dark"><a
                            href="https://www.smashwords.com/books/view/278165">Golden Dozen</a></button>
                </figcaption>
            </figure>
        </div>

        <div class="card">
            <figure class="book card-body">
                <img src="../assets/leadershipmantras.jpg" class="highlight" alt="Leadership Mantras & Sayings" />
                <br>
                <figcaption>
                    <p class="highlight-caption">Great leaders recognize that their team members have considerable
                        experience, knowledge and creativity. These leaders cultivate a welcoming atmosphere of free
                        idea exchange to engage their team members and encourage them to contribute their best. That’s
                        where mantras come in. Mantras are easy-to-remember sayings that remind us, and keep us in
                        alignment with our leadership style.</p>
                    <button type="button" class="btn btn-dark"><a
                            href="https://www.smashwords.com/books/view/290556">Leadership Mantras &
                            Sayings</a></button>
                </figcaption>
            </figure>
        </div>

    </div>
</template>

<script setup>

</script>

<style scoped>
.book-flex {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    justify-content: center;
}

.book {
    width: 250px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

figcaption {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
}

a {
    color: #009DF8;
    text-decoration: none;
}
</style>
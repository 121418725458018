<template>
  <section>
    <h1>Testimonials</h1>
    <br>
    <h4>To understand the “why” Karl does what he does here are some of the comments clients have said of Karl and his
      services to date:</h4>
    <ul>
      <li>“I felt like Karl looked into my soul. He has a wonderful way about him that made me feel comfortable. He had
        concrete suggestions and guidance. I think the assessment and meeting with him will make a positive difference
        in my private/professional life. Thank-you.”</li>
      <li>“Karl was exceptionally good and I was deeply impressed by his ability to so accurately interpret the
        assessment results and provide meaningful, practical action-oriented advice.”</li>
      <li>“Right on the money! Indispensable advice and coaching. Thanks.”</li>
      <li>“A trusted advisor! And that has made all the difference…I trust him!”</li>
      <li>“A great ability to really listen and to convey the right message in a way we can hear it and act on it.”</li>
    </ul>
    <br>
    <h4>More Testimonials:</h4>
    <p class="quote">“Meeting Karl 10 years ago was a defining moment in my professional career. What continually amazes
      me is his ability to illuminate the “big picture” and then drill down in to specific issues and provide honest
      feedback in a caring manner. Karl is profoundly professional and trustworthy. I highly recommend his services if a
      person is open to receiving truly accurate feedback regarding who they are!” April 15, 2012</p>
    <p>Top qualities: Great Results, Expert, High Integrity</p>
    <p>Brian Glover - Vice President Asset Protection at Stornoway Diamond Corporation</p>
    <p>Hired Karl as a Business Consultant in 2002, and has been happy to hire Karl more than once.</p>
    <p class="quote">
      “We have been working with Karl since 2003 – when BIOX was just seven people, an office and one heck of a great
      idea. Today, BIOX is 50 people, one commercial biodiesel plant and another one in the works – Karl has been
      instrumental in helping us build the BIOX team.</p>
    <p>Karl is a remarkable individual with extraordinary human qualities. He combines coaching with strategic
      consulting that facilitates powerful, organizational transformations one person at a time.</p>
    <p>
      His TAIS assessment analysis provides the building blocks for building a team and he has been an integral part of
      our ongoing development. Karl makes it possible to challenge ourselves to examine our strengths and weaknesses –
      both as an individual and as a group so that we can create positive change. Karl’s support is an essential
      component to the continual endeavour of building the high performance team.”</p>
    <p>Nak Paik - Vice President of Operations at Biox Corporation</p>
  </section>
</template>

<script setup>

</script>

<style scoped>
li,
p,
h4,
h1 {
  text-align: left;
}

.quote {
  font-weight: 700;
}
</style>
<template>
  <p>“Meeting Karl 10 years ago was a defining moment in my professional career. What continually amazes me is his ability to illuminate the 'big picture' and then drill down in to specific issues and provide honest feedback in a caring manner. Karl is profoundly professional and trustworthy. I highly recommend his services if a person is open to receiving truly accurate feedback regarding who they are!”
    <span class="brian"> - Brian Glover, VP Asset Protection at Stornoway Diamond Corporation</span>
  </p>
  <router-link class="testimonials" :to="{name: 'TestimonialsView'}">More Testimonials<i class="fa fa-arrow-right"></i></router-link>
</template>

<script setup>

</script>

<style scoped>
p {
    text-align: left;
    letter-spacing: 2px;
    text-shadow: 2px 1px #21252925;
    font-size: 1.1em;
    display:block;
}
.testimonials {
    display: inline-block;
    color: #009df8;
    text-decoration: none;
    padding: 0.5em;
    text-shadow: none;
}
.fa-arrow-right {
    color: #009df8;
    margin-left: 4px; 
}
.testimonials:hover {
    background-color: #212529;
    border-radius: 4px;
}
</style>
<template>
  <h1>Frequently Asked Questions</h1>
  <h3>Below are some questions we get a lot...</h3>
  <section>
    <FAQ title="What experience do you have as a coach and in business?"
      :content="[
      'Karl has authored 5 eBooks all focused on the continued development of people and/or organizations.',
      'He has also published 2 App’s currently sold with Apple Apps in the Business section. One App is the Sales Funnel for early stage sales transactions/acquisitions for small businesses. The second App is My Leadership for personal strategic thinking and activity development.',
      'Karl has the reputation  not only for being able to pinpoint the path that people and organizations should embark on with the specific “what, why, when, and with whom,” but he is also uniquely skilled and capable as far as providing the “step-by-step how-to.” When people want solid,  grounded and practical advice as well as superb executive coaching they turn to him!',
      'Karl brings to his consulting practice over 20 years of industry, coaching, and advisory experience.  His experience ranges from vice president, sales and marketing to senior executive financial roles in addition to being in the executive coaching business advisory practice for the past 20 years.',
      'Aside from being in his own business Karl has been an 12 year member of the Performance Coaching team led by Peter Jensen that teaches at the Queen’s Executive Leadership Program that is consistently ranked among the top five executive developmental programs in the world!',
      'As an Executive Coach and Business Advisor, Karl brings a rich blend of experience and a warm but candid and transparent style to the table in order to provide insights that unfailingly guide people and organizations in making the changes that will ultimately enhance their performance.',
      'Karl has extensive experience in performance assessments, business advisory and executive coaching. Using renowned assessment tools (TAIS, Extended Disc, Insights Colours, & Myers Briggs) as an integral part of his work Karl provides clients with extremely accurate information and insight into the impact their style and personal characteristics will have on their ability to perform under various circumstances and to establish superior performance.',
      ]"
      identity="aboutKarl"
      ></FAQ>
    
  <FAQ title="How would you to describe your business and what it is you do?"
  :content="[
    'We are executive coaches and business advisors who specialize in working both with organizations as a whole and the individuals that make up those organizations. The difference between the coaching and the advisory work lies partly in the line between soft skills (communication, active listening, problem solving) and hard skills (actually learning how to read the financials, creating and working with a functional dashboard).  In addition, it involves the organization’s actual strategy, structure, and the behind-the-scenes management of professionally run businesses like partnerships (i.e. management companies, holding and operating companies, boards, etc.). The above components represent a significant part of our coaching & advisory work with clients.',
    'The combination of the two is not easily found in the ranks of executive coaching or in the pure advisory ranks, however we are highly proficient in both areas, which sets us apart significantly from other coaching agencies.',
    'Based on these principles, we create conversations that matter, delivering a clear path and action plan which enables you to link your passion with execution!'
  ]"
  identity="whatIsBusiness"
  ></FAQ>
  <FAQ
  title="How long have you been in business?" 
  :content="['Stryder represents over 19 years of independent business ownership.']" 
  identity="timeInBusiness"
  ></FAQ>
  <FAQ 
  title="What does this service/product do for your client? Does it accomplish certain specific things or help solve issues?"
  :content="['Our service provides our clients with a confidential and impactful ‘sounding board’ experience that delivers the information, advice, and knowledge they need to compete at and sustain high levels of success while capitalizing on their assets to gain the advantage. Our plans and coaching offer tangible information and impactful solutions they just can’t get anywhere else.']"
  identity="whatService"
  ></FAQ>
  <FAQ
  title="Who are your competitors? List them if you can."
  :content="['If one takes into account the combined ‘all in one service’ of coaching and business advice that Stryder offers, there really is very little competition.  If you look at the services separately there are a multitude of ‘consultants’ that present themselves as ‘coaches’ without necessarily having the background or experience represented in house at Stryder Performance.']"
  identity="whatCompetitors"
  ></FAQ>
  <FAQ
  title="Describe your unique business “story” (what made you decide to do what you do)."
  :content="['In short, we facilitate conversations that matter!', 
  'We walk the talk of what we coach, thus realizing the benefits of the “leg work”.  Specifically, the process represents a continued refinement of our business and personal vision statements and the alignment of all of our business activities towards our vision. Stryder’s vision is; selfishly if we are to think of “why” we do what we do, it is the tremendous pride and gratification we derive from helping others reach their potential and achieve success on a level they perhaps could only dream of!', 
  'When a client says to us; “in these difficult times, I need you now more then ever”, this acknowledgement echoes loud and clear that we are achieving our vision!']"
  identity="uniqueBusinessStory"
  ></FAQ>
  <FAQ
  title="Describe a success story or two in which your expertise helped a client significantly."
  :content="['We’ve been instrumental in helping senior executives within organizations successfully move from VP roles into the CEO role via the coaching process. One example in particular: a COO to CEO and then through a self-induced merger for the long term success of the business, then led him back into the COO role of the much larger business entity. Our coaching was instrumental in helping him realize his CEO goal and again during a difficult merger process. We continue to work with this individual 5 years later working with his key people.']"
  identity="successStory"
  ></FAQ>
  <FAQ
  title="Outside of the contact list that you/we provide, what kinds/types of prospects would you love to have as clients?"
  :content="['More of the same clients we currently enjoy working with. Owners and senior executive as well as high potential fast paced individuals wanting to push their learning.']"
  identity="clientType"
  ></FAQ>
  <FAQ
  title="What questions do you use to qualify a prospect before setting the appointment?  Please list pre-qualifying questions that we can ask Stryder Performance."
  :content="['An interesting point of comparison is to ask the prospect at some point what keeps him or her up and night (in this case we might ask what keeps his or her members up at night? What’s on their radar? We listen and at some point compare their answers to the research on this question gathered from all of the other senior business leaders we have met to date…keeping it current. For us the answers really help to qualify if we are speaking to a like -minded individual who is passionate and current in his or her business thoughts and truly is thinking about the business today and in the future. It speaks to being open thinking and being willing to continuously learn.',
  'What we usually hear are; Growth challenges, Strategy execution, Customer relationships, People (having the right ones in the right positions doing the right things, succession, utilization), Profitability, Capital (use and sourcing), The unknown-tomorrow, Competition, Leadership – their own, The impact of the current state of the world on their business.',
  'Have you been exposed to either or both coaching, advisory consulting and /or assessments? What was your experience? For John and I it’s often a good thing that the person has prior exposure to our services (good or bad) as we can then build on their knowledge and experience to date.',
  'To help them along at some point we will ask almost “reverse” questions…If I were in your shoes one of the questions I’d ask Stryder Performance; if you believe in your services do you have a coach? The answer is yes and he continues to provide timely spot on advice.  We then expand on the types of learning we have had from our own coaches and advisors.',
  'Another question that we would suggest they would ask is; What would a successful coaching and/or advisory program look like? Also how can I measure success?',
  'Please list some common objections and your responses.'
  ]"
  identity="prospectQuestions"
  ></FAQ>
  <FAQ
  title="I have been exposed to assessments, coaching and expert advice before and have not really been moved or inspired by any of them."
  :content="[
    'I understand that and frankly I have been there myself and hear the story often through with my professional network of advisors. That was until I found the expertise and the right instrument that ultimately took me and my business success to the next level. The message: like a smart business executive, you continue your search for the decided advantage and your search never ends. The key here: this jump start towards individual clarity is found within a 1.75 hour investment of time and resources-that’s unique!'
  ]"
  identity="previousAssessments"
  ></FAQ>
  <FAQ
  title="My business is so different and the challenges so unique, I don’t see the link to how you can help me?"
  :content="[
    'You are correct: your business is unique and has its own set of challenges and that is likely why you do it and why you continue to be successful. This ‘conversation that matters’ embraces that uniqueness goes well beyond a typical personality or business skill assessment de-brief.',
    'People are their best in the right spots, doing the right things…engaged and empowered to make it happen-our conversation optimizes this ‘Jim Collin’s’ principal.'
  ]"
  identity="differentBusiness"
  ></FAQ>
  <FAQ
  title="Why would I do this program? Why is it important to me and what should I expect to get out of it?"
  :content="[
    'One the most important questions that underlies the question should I do this or any program or service and what should I expect to get out of it is the question; Is this actually useful? This is the most important question and the answer has to be anything one undertakes must be useful! Simply just doing something or making something is a waste of time and effort. Some things are cool, fun to do, and perhaps the flavour of the month or a trend. All these tend to wear off in time whereas useful never does. This program is useful and useful to anyone who takes the time to go through it.'
  ]"
  identity="whyImportant"
  ></FAQ>
  <FAQ
  title="Is it really worth it?"
  :content="[
    'In the end you will be the one to really judge for yourself. because it will only be worth it to you. So know this, don’t take our fancy marketing and words at face value to help you judge. Rather work with the facts and what others have said and experienced;',
    '“You helped me achieve professional success by clearly outlining a road map and a specific set of things to do…the result is that I went from a COO role to the CEO.”',
    '“ You captured it all and put the data in a format I could understand and deal with…you changed my life!”',
    '“Spot on, valued advice and you made all the difference!”'
  ]"
  identity="worthIt"
  ></FAQ>
</section>
</template>
<script>
import FAQ from '../components/FAQ.vue'
export default {
  components: {
    FAQ
  }
}
</script>

<style scoped>
  
</style>
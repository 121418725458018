// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDZPN5zk1RSLOAO47FLxQFEBjFyLRf1zto",
    authDomain: "stryder-performance.firebaseapp.com",
    projectId: "stryder-performance",
    storageBucket: "stryder-performance.appspot.com",
    messagingSenderId: "506369711764",
    appId: "1:506369711764:web:6cf75a81b48cb9df6a9e81",
    measurementId: "G-R6WHMVGEV0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

export {
    db, auth, storage
}
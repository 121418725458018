<template>
  <section>
    <div class="img-grid">
      <div class="grid-item caesar-title">
        <h1 class="title">Great Lessons From Some Famous Historical Leaders</h1>
        <br>
        <h4>Julius Caesar and the Art of Thinking Strategically</h4>
        <br>
        <router-link class="testimonials" to="/blog/#caesar">Stryder Blog <i class="fa fa-arrow-right"></i>
        </router-link>
      </div>
      <div class="grid-item">
        <img src="../assets/caesar.png" class="image" alt="Julius Caesar" />
      </div>
    </div>
  </section>
  <section>
    <div>
      <br>
      <TestimonialsTab></TestimonialsTab>
      <br>
    </div>
  </section>
  <section>
    <Inquiry></Inquiry>
  </section>
  <section>
    <Apps></Apps>
  </section>

</template>

<script>
import Apps from '../components/Apps.vue'
import BlogHighlights from '../components/BlogHighlights.vue';
import TestimonialsTab from '../components/TestimonialsTab.vue';
import FAQ from '../components/FAQ.vue'
import Inquiry from '../components/Inquiry.vue'

export default {

  name: 'HomeView',
  components: {
    Apps,
    BlogHighlights,
    TestimonialsTab,
    FAQ,
    Inquiry
  },

}
</script>
<style scoped>
.caesar-title {
  position: relative;
  z-index: 1;
}

.img-grid {
  position: relative;
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  align-content: center;
  justify-items: start;
  text-align: start;
  justify-items: center;
  overflow-x: hidden;
}

.image {
  width: 100%;
}

section {
  position: relative;
}

section:first-of-type::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100%;
  background-image: linear-gradient(to right, rgba(0, 157, 248, 0.10), rgba(0, 157, 248, 1));
}

@media screen and (max-width: 768px) {
  .img-grid {
    grid-template-columns: 1fr;
  }

}

h1 {
  display: block;
  text-align: start;
  margin: 0;
  resize: none;
  font-size: 2.8rem;
}

h4 {
  display: block;
  margin: 0;
  text-align: start;

}

.fa-arrow-right {
  color: #009df8CC;
  margin-left: 4px;
}

.testimonials {
  display: inline-block;
  color: #009df8CC;
  text-decoration: none;
  padding: 0.5em;
  text-shadow: none;
  position: relative;
  /* right: 0.5em; */
}

.testimonials:hover {
  background-color: #212529;
  border-radius: 4px;
}
</style>
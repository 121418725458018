<template>
  <section class="about-karl">
  <h3>About Karl</h3>
  <img class="karl-pic" src="../assets/karlfaceshot.jpg" alt="Karl Hagglund"/>
  <p>Karl has authored 5 eBooks all focused on the continued development of people and/or organizations.</p>
  <p>He has also published 2 App’s currently sold with Apple Apps in the Business section. One App is the Sales Funnel for early stage sales transactions/acquisitions for small businesses. The second App is My Leadership for personal strategic thinking and activity development.</p>
  <p>Karl has the reputation  not only for being able to pinpoint the path that people and organizations should embark on with the specific “what, why, when, and with whom,” but he is also uniquely skilled and capable as far as providing the “step-by-step how-to.” When people want solid,  grounded and practical advice as well as superb executive coaching they turn to him!</p>
  <p>Karl brings to his consulting practice over 20 years of industry, coaching, and advisory experience.  His experience ranges from vice president, sales and marketing to senior executive financial roles in addition to being in the executive coaching business advisory practice for the past 20 years.</p>
  <p>Aside from being in his own business Karl has been an 12 year member of the Performance Coaching team led by Peter Jensen that teaches at the Queen’s Executive Leadership Program that is consistently ranked among the top five executive developmental programs in the world!</p>
  <p>As an Executive Coach and Business Advisor, Karl brings a rich blend of experience and a warm but candid and transparent style to the table in order to provide insights that unfailingly guide people and organizations in making the changes that will ultimately enhance their performance .</p>
  <p>Karl has extensive experience in performance assessments, business advisory and executive coaching. Using renowned assessment tools (TAIS, Extended Disc, Insights Colours, & Myers Briggs) as an integral part of his work Karl provides clients with extremely accurate information and insight into the impact their style and personal characteristics will have on their ability to perform under various circumstances and to establish superior performance.</p>
</section>
</template>

<script>
export default {

}
</script>

<style scoped>
.karl-pic {
  border-radius: 32px;
}
.about-karl {
  padding: 0px 10vw;
}
.in-line {
  display: inline;
}
</style>
<template>
    <h1>General Inquiries</h1>
    <br>
    <h2>Leave an inquiry below and Karl will email you back within 2 business days</h2>
    <br>
    <form @submit.prevent="submitForm">
        <input type="text" placeholder="Name" class="form-control" v-model="inquiryName" maxlength="64">
        <br>
        <input type="email" placeholder="Email" class="form-control" v-model="inquiryEmail" maxlength="128">
        <br>
        <textarea class="inquiry-content form-control" v-model="inquiryContent" maxlength="2000"
            placeholder="Inquiry/Question"></textarea>
        <br>
        <input type="submit" value="Send" class="btn btn-primary">
    </form>
    <br>
    <p v-if="inquiryCompleted">{{ inquiryCompleted }}</p>
</template>

<script setup>
import { ref } from 'vue'
import { db } from '@/firebase';
import { collection, addDoc } from 'firebase/firestore'
const inquiryName = ref('')
const inquiryEmail = ref('')
const inquiryContent = ref('')

const inquiryCompleted = ref(null)

const inquiryCollection = collection(db, 'inquiries')

const submitForm = async () => {
    try {
        await addDoc(inquiryCollection, {
            name: inquiryName.value,
            email: inquiryEmail.value,
            content: inquiryContent.value,
            dateTime: new Date().toISOString()
        })
        inquiryName.value = ''
        inquiryEmail.value = ''
        inquiryContent.value = ''
        inquiryCompleted.value = 'Inquiry sent. Expect an email from Karl within 2 business days!'
    } catch (e) {
        inquiryCompleted.value = "Sorry, failed to post inquiry. Please check your connection."
    }
}

</script>

<style scoped>
.btn {
    background-color: #212529;
    color: rgb(0, 157, 248);
    border: none;
}

input {
    max-width: 600px;
    margin: 0 auto;
    display: block;
}

textarea {
    resize: none;
    max-width: 600px;
    margin: 0 auto;
    height: 200px;
}
</style>
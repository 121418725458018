<template>
  <div class="apps-container">
  <figure class="card">
    <div class="card-body">
    <img class="app-img" src="../assets/salesfunnel.png" alt="Stryder Sales Funnel logo">
    <figcaption>
      <h3>Sales Funnel</h3>
      <p class="app-caption">Follow your prospective customers to a final sale</p> 
      <br>
      <a href="https://apps.apple.com/us/app/sales-funnel/id566688532" target="_blank">
        <img src="../assets/appstore.svg" alt="View on the App Store" class="store-logo">
      </a>
      <a href='https://play.google.com/store/apps/details?id=com.stryderperformance.salesfunnel' target="_blank">
        <img class="play-logo" alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
      </a>
      
    </figcaption>
    </div>
  </figure>
  <figure class="card">
    <div class="card-body">
    <img class="app-img" src="../assets/swot.png" alt="Stryder SWOT Analysis logo">
    <figcaption>
      <h3>SWOT Analysis</h3>
      <p class="app-caption">Examine internal and external factors affecting your business</p>
      <br>
      <a href="https://apps.apple.com/us/app/swot-analysis/id1638383961" target="_blank">
        <img src="../assets/appstore.svg" alt="View on the App Store" class="store-logo">
      </a>
      <a href='https://play.google.com/store/apps/details?id=com.stryderperformance.swotapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target="_blank">
        <img class="play-logo" alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
      </a> 
    </figcaption>
    </div>
  </figure>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.app-img {
  border-radius: 32px;
  width: 180px;
  height: 180px;
}
.apps-container {
    display: flex;
    justify-content: center;
    align-items:flex-start;
    flex-wrap: wrap;
    gap: 8px;
}
.app-caption {
    width: 200px;
    display: block;
    margin: 0;
}
.play-logo {
  width: 128px;
}
.store-logo {
  width: 100px;
}
a {
  display: block;
}
</style>
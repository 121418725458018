import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ProductsAndServicesView from '../views/ProductsAndServicesView.vue'
import AboutKarlView from '../views/AboutKarlView.vue'
import TestimonialsView from '../views/TestimonialsView.vue'
import FAQView from '../views/FAQView.vue'
import BlogView from '../views/BlogView.vue'
import AdminView from '../views/AdminView.vue'



const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: HomeView
  },
  {
    path: '/products-and-services',
    name: 'ProductsAndServicesView',
    component: ProductsAndServicesView
  },
  {
    path: '/about',
    name: 'AboutKarlView',
    component: AboutKarlView
  },
  {
    path: '/testimonials',
    name: 'TestimonialsView',
    component: TestimonialsView
  },
  {
    path: '/faq',
    name: 'FAQView',
    component: FAQView
  },
  {
    path: '/blog',
    name: 'BlogView',
    component: BlogView
  },
  {
    path: '/admin',
    name: 'AdminView',
    component: AdminView
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
  scrollBehavior(to, from, savedPostion) {
    return { top: 0, behavior: 'instant' }
  }
})

export default router

<template>
<footer>
  <p class="text-secondary">© Stryder Performance Management Inc. 2022</p>
  <p class="text-muted">+1 (416) 436-6239</p>
  <p class="text-muted">karl@stryderperformance.com</p>
</footer>
</template>

<script>
export default {

}
</script>

<style scoped>
p {
    text-align: center;
    margin:0;
}
footer {
  background-color: #212529;
}
</style>
<template>
    <section>
        <h1>Admin</h1>
        <form v-if="!signedIn" @submit.prevent="signInUser">
            <input type="email" placeholder="Email" v-model="email">
            <input type="password" placeholder="Password" v-model="password">
            <input type="submit" value="Sign In">
        </form>
        <div v-else class="signed-in-options">
            <h1>Welcome Back!</h1>
            <h2>Admin Actions:</h2>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Create Blog Post</h5>
                    <form @submit.prevent="createBlogPost" class="card-text">
                        <input ref="blogPostFile" type="file" class="blog-input form-control" />
                        <p>or</p>
                        <input type="text" placeholder="YouTube Video URL" class="blog-input form-control"
                            maxlength="500" v-model="blogPostYoutube" />
                        <p>* YouTube Url or Photo is required for a blog post</p>
                        <br>
                        <input type="text" placeholder="Post Title (required)" required class="blog-input form-control"
                            maxlength="128" v-model="blogPostTitle" />
                        <br>
                        <textarea rows="5" class="blog-input form-control" placeholder="Blog Content (required)"
                            maxlength="10000" minlength="32" v-model="blogPostContent"></textarea>
                        <br>
                        <input type="submit" class="btn btn-dark" value="Post to Blog!">
                    </form>
                </div>
                <div class="card-body blog-post" v-for="blog in blogPosts">
                    <h5>{{ blog.title }}</h5>
                    <button v-on:click="deletePost(blog.id, blog.image)"><i class="fa fa-trash"
                            aria-hidden="true"></i></button>
                </div>
                <br>
                <br>
                <h4>Inquiries</h4>
                <div class="card-body inquiries-div" v-for="inq in inquiries">
                    <div>
                        <h5>{{ inq.name }}</h5>
                        <p>{{ inq.email }}</p>
                        <p>{{ inq.content }}</p>
                    </div>
                    <button v-on:click="deleteInquiry(inq.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>
                </div>

            </div>
        </div>
    </section>
</template>

<script setup>
import { ref } from 'vue'
import { db, auth, storage } from '../firebase/index.js'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { addDoc, onSnapshot, collection, query, orderBy, deleteDoc, doc } from 'firebase/firestore'
import { getDownloadURL, ref as storageRef, uploadBytes, deleteObject } from "firebase/storage"
import { onMounted } from '@vue/runtime-core'
import { v4 } from "uuid";

//ref to check if signed in
const signedIn = ref(false)

// sign in screen refs
const email = ref('')
const password = ref('')

// blog posts refs
const blogPosts = ref([])
const blogCollection = collection(db, 'blog')
const dateQuery = query(blogCollection, orderBy('date', 'desc'))

// inquiry collection
const inquiries = ref([])
const inquiriesCollection = collection(db, 'inquiries')
const inquiriesQuery = query(inquiriesCollection, orderBy('dateTime', 'desc'))

onMounted(() => {
    auth.onAuthStateChanged((user) => {
        if (user) {
            // user is signed in
            signedIn.value = true;
            onSnapshot(dateQuery, { includeMetadataChanges: true }, (querySnapshot) => {
                const fbBlogPosts = []
                querySnapshot.forEach((doc) => {
                    const blog = {
                        title: doc.data().title,
                        content: doc.data().content.split('\n'),
                        video: doc.data().video,
                        date: new Date(doc.data().date).toDateString(),
                        image: doc.data().image,
                        id: doc.id
                    }
                    fbBlogPosts.push(blog)
                })
                blogPosts.value = fbBlogPosts
            })
            onSnapshot(inquiriesQuery, { includeMetadataChanges: true }, (q) => {
                const fbInquiries = []
                q.forEach((e) => {
                    const inquiry = {
                        name: e.data().name,
                        content: e.data().content,
                        email: e.data().email,
                        dateTime: new Date(e.data().date).toDateString(),
                        id: e.id,
                    }
                    fbInquiries.push(inquiry)
                })
                inquiries.value = fbInquiries
            })
        } else {
            //user is not signed in
            signedIn.value = false;
        }
    })
})

const deletePost = async (id, image) => {
    try {
        console.log(image)
        if (image) {
            const imageRef = storageRef(storage, image);
            deleteObject(imageRef);
        }
        await deleteDoc(doc(db, 'blog', id));
    } catch (e) {
        alert("Failed to delete post. Please check your connection and try again.")
    }

}

const deleteInquiry = async (id) => {
    try {
        await deleteDoc(doc(db, "inquiries", id));
    } catch (e) {
        alert("Failed to delete inquiry. Please check your connection and try again.")
    }
}


const signInUser = async () => {
    try {
        await signInWithEmailAndPassword(auth, email.value, password.value);
        email.value = ''
        password.value = ''
    } catch (e) {
        alert('Failed to sign in. Please try again.')
    }
}

////// Blog Post Information/Refs ///////
const blogPostTitle = ref('')
const blogPostContent = ref('')
const blogPostYoutube = ref('')
const blogPostFile = ref(null);

const createBlogPost = async () => {

    // declare new strings
    let title = blogPostTitle.value;
    let content = blogPostContent.value;
    let youtube = null;
    let date = new Date().toISOString()
    let file = blogPostFile.value.files[0];


    // format youtube url
    if (blogPostYoutube.value.includes('watch?v=')) {
        youtube = blogPostYoutube.value.replace('watch?v=', 'embed/')
    } else if (blogPostYoutube.value.includes('watch')) {
        youtube = blogPostYoutube.value.replace('watch', 'embed')
    } else if (blogPostYoutube.value.includes('embed')) {
        youtube = blogPostYoutube.value;
    }

    // add js api call
    if (youtube) youtube = youtube + "?enablejsapi=1";

    // if file is uploaded and yt url exist, throw alert and return
    if (file && youtube) {
        console.log(file)
        alert("Youtube link and image cannot both be uploaded. Please remove one and try again.")
        return;
    }

    try {
        // if file exists, upload it
        let fileUrl = null
        if (file) {
            const fileRefString = `images/${file.name}${v4()}`
            const fileRef = storageRef(storage, fileRefString)
            await uploadBytes(fileRef, file)
            fileUrl = await getDownloadURL(fileRef)
        }

        // create document in db
        await addDoc(collection(db, 'blog'), {
            title: title,
            content: content,
            video: youtube,
            date: date,
            image: fileUrl
        })

        // reset all refs with v-bind
        blogPostContent.value = '';
        blogPostTitle.value = '';
        blogPostYoutube.value = '';

    } catch (e) {
        console.log(e)
        alert("Failed to create blog post. Please check your connection and try again.")
    }
}

</script>

<style scoped>
input[type='submit'] {
    color: #009df8;
    background-color: #212529;
}

.blog-input {
    display: block;
    width: 100%;
    resize: none;
}

.blog-post h5 {
    overflow: hidden;
}

.blog-post {
    border: 1px #21252944 solid;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    margin: 0 8px;
    margin-bottom: 16px;
}

button {
    border: none;
    background-color: transparent;
}

i {
    color: red;
}

.inquiries-div {
    border: 1px #21252944 solid;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    margin: 0 8px;
    margin-bottom: 16px;
}
</style>
<template>
  <NavBar></NavBar> 
  <div class="nav-padding"></div>
  <router-view/>
  
  <Footer></Footer>
</template>

<script>
import NavBar from './components/NavBar.vue'
import Footer from './components/Footer.vue'
export default {
  components: {
    NavBar,
    Footer
  },
  setup() {}
}

</script>

<style>
#app {
  font-family: Gill Sans ,Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /* background-color: #EBFBFF; */
  background-color: #ffffff
}

nav {
  padding: 0;
}
.nav-padding {
  width: 100%;
  height: 115px;
}
</style>

<template>
    <nav class="navbar navbar-expand-md navbar-dark bg-dark fixed-top">
  <div class="container-fluid">
    <router-link class="navbar-brand" :to="{name: 'HomeView'}"><img class="img-home" src="../assets/Stryder-inverted.png" alt="Stryder Performance Logo"/></router-link>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
      <div class="navbar-nav">
        <router-link class="nav-link" :to="{name:  'ProductsAndServicesView'}">Products & Services</router-link>
        <router-link class="nav-link" :to="{name:  'TestimonialsView'}">Testimonials</router-link>
        <router-link class="nav-link" :to="{name:  'FAQView'}">Stryder FAQ</router-link>
        <router-link class="nav-link" :to="{name:  'BlogView'}">Blog</router-link>
      </div>
    </div>
  </div>
</nav>
</template>

<script>
export default {
}
</script>

<style scoped>
.navbar {
    padding: 0;
}
.img-home {
    width: 300px;
    padding: 0;
    margin: 0;
    color: white;
}
div {
  margin: 0;
}
.navbar-brand {
    margin: 0;
    margin-right: 16px;
    padding: 0;
}

</style>
<template>
    <div class="all-services">
        <div class="card">
            <div class="card-body">
                <h3>Executive Coaching</h3>
                <p>The Stryder Performance Executive Coaching Program is a confidential, one-on-one executive
                    development program tailored to meet your individual needs. Our Executive Coaching Program builds on
                    your strengths and talents, providing the practical, hands-on skills and inspiration you need to
                    perform consistently at your peak potential and beyond!</p>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <h3>Employee Selection & Succession Planning. The Stryder Advantage</h3>
                <p>Getting employee selection right is essential in every manager’s competitive, fast-paced world.
                    Employees fail because their psychological strengths and weaknesses are not well matched to the
                    demands of the performance environment. We utilize the exceptionally accurate assessment exercises
                    to ensure you get it right when it comes to new employee selection and employee succession planning
                </p>
            </div>
        </div>
    </div>
</template>

<script scoped>

</script>

<style scoped>
.all-services {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    justify-content: center;
}

p {
    text-align: left;
}

h3 {
    text-align: left;
}

.card-body {
    width: 250px
}
</style>
<template>
    <div class="outside">
        <a data-bs-toggle="collapse" :href="'#' + identity" v-bind:aria-expanded="expanded" v-on:click="changeExpanded">
            <div class="faq-card">

                <p><strong>{{ title }}</strong></p>
                <i v-if="expanded" class="fa fa-minus" aria-hidden="true"></i>
                <i v-else class="fa fa-plus" aria-hidden="true"></i>
            </div>

        </a>
        <div class="collapse" :id="identity">
            <div>
                <p v-for="(c, index) in content" :key="index"> {{ c }} </p>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
export default {
    props: ['title', 'content', 'identity'],
    setup(props) {
        const expanded = ref(false)

        const changeExpanded = async () => {
            expanded.value = !expanded.value
            console.log(expanded.value)
        }

        return {
            expanded, changeExpanded
        }
    }
}
</script>

<style scoped>
.faq-card {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

a {
    text-decoration: none;
}

p {
    text-align: left;
}

.outside {
    border-top: 2px solid #009DF8;
}
</style>
<template>
  <section>
    <div class="spinner">
      <i v-if="blogPosts.length === 0" class="fa fa-spinner" aria-hidden="true"></i>
    </div>
    <div class='card' v-for="blog in blogPosts" :key="blog.id">
      <h2>{{ blog.title }}</h2>
      <br>
      <div class="card-body">

        <div class="grid">
          <div class="media">
            <iframe v-if="blog.video" height="400" :src="blog.video" title="YouTube video player" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen></iframe>
            <img v-if="blog.image" :src="blog.image" :alt="blog.title" class="blog-image" />
          </div>
          <div class="content">
            <p v-for="(paragraph, index) in blog.content" :key="index">{{ paragraph }}</p>
            <p><i>{{ blog.date }}</i></p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section>
    <h1>Classic Articles</h1>
    <div class="card" id="caeser">
      <div class="card-body">
        <h2>Julius Caesar and the Art of Thinking Strategically</h2>
        <br>
        <div class="grid">

          <div class="media">
            <img src="../assets/caesar-blog.jpeg" alt="Julius Caesar">
          </div>
          <div class="content">
            <p>Of the many qualities that made Caesar a great leader, perhaps none were more important than his
              proficiency as a strategist. In both military and political matters, Caesar’s strategies proved effective.
            </p>
            <p>One such strategy, divide and conquer, was made famous by Caesar while fighting the Gallic Wars. Also
              during this war came one of Caesars greatest challenges and triumphs in the form of the 52BC siege of
              Alesia. Defending this nearly impregnable Gallic stronghold was Vercingetorix, the leader of the Gallic
              Confederacy, at the head of 80,000 warriors. Caesar quickly implemented his plan to capture the city and
              began construction of siege works. Caesar planned to build a wall encircling the town, which would be
              defended by towers, battlements, and trenches. When completed the wall extended 10 miles, and formed a
              ring around Alesia.</p>
            <p>However, before it was fully finished, Vercingetorix sent out riders to gather reinforcements from the
              tribes of Gaul. Caesar immediately ordered the construction of a second wall, meant to defend against the
              reinforcements that would soon arrive. This exterior wall formed a 14-mile barrier, and was strengthened
              with towers, traps, pits, and two large trenches. Caesar had the inner trench filled with water diverted
              from nearby streams. Knowing that when reinforcements arrived his troops would be unable to forage, Caesar
              had each man gather enough food to last a month.</p>
            <p>Alas, Vercingetorix’s plea was answered and 260,000 Gauls arrived to put Caesars plan to the test. With
              this large army, the Gallic forces now outnumbered the Romans 4 to 1. While simultaneously defending
              multiple fronts Caesars army managed to stave off the perpetual onslaught of the two armies which were
              fighting desperately to force a passage through the Roman defenses. Caesar waited patiently for the
              opportune moment to counter the reinforcing army and when the opportunity presented itself. he ordered the
              attack and sent his cavalry out to flank the enemy and deliver the decisive blow.</p>
            <p>Devastated and panic stricken, the supporting army fled the field of battle, pursued by Caesar’s cavalry.
              As the remnants of his army lay in ruin, completely surrounded and with no hope of further aid,
              Veringetorix issued his surrender and acknowledged Roman rule.</p>
            <p>By way of superior strategy and discipline, a smaller army had bested a much larger force and although
              Caesar’s career was far from over, the conquest of Gaul was nearly complete.</p>
            <p>By Bjorn Hagglund</p>
            <p><i>Thurs Sept 13 2012</i></p>
          </div>
        </div>

      </div>
    </div>
  </section>

</template>

<script setup>
import { onMounted, ref } from 'vue';
import { db } from '../firebase/index'
import { collection, onSnapshot, doc, query, orderBy } from 'firebase/firestore'
const blogCollection = collection(db, 'blog')
const dateQuery = query(blogCollection, orderBy('date', 'desc'))
const blogPosts = ref([])

onMounted(async () => {
  onSnapshot(dateQuery, { includeMetadataChanges: true }, (querySnapshot) => {
    const fbBlogPosts = []
    querySnapshot.forEach((doc) => {
      const blog = {
        title: doc.data().title,
        content: doc.data().content.split('\n'),
        video: doc.data().video,
        date: new Date(doc.data().date).toDateString(),
        image: doc.data().image,
        id: doc.id
      }
      fbBlogPosts.push(blog)
      console.log(blog.video)
    })
    blogPosts.value = fbBlogPosts
  })
})

</script>

<style scoped>
.card {
  margin-bottom: 8px;
}

.card-body .grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

@media screen and (max-width: 768px) {
  .card-body .grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
  }
}

p {
  text-align: left;
  max-width: 75ch;
  text-overflow: ellipsis;
}

img,
iframe {
  width: 100%
}

.blog-image {
  width: 100%;
  height: auto;
  max-height: 700px;
}
</style>
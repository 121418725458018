<template>
    <div class="blog-highlights">
            <figure class="card">
                <div class="card-body">
                <img src="../assets/Doc-Heart1.png" class="highlight" alt="Dr. Stickly"/>
                <figcaption>
                    <p class="highlight-caption">Come see Doctor Stick Tip’s for making your business as healthy as possible!</p>
                    <button type="button" class="btn btn-dark">Dr. Stickly's Tips</button>
                </figcaption>
                </div>
            </figure>
            <figure class="card">
                <div class="card-body">
                <img src="../assets/Spy-Hiding1.png" class="highlight" alt="Agent Jason Stick"/>
                <figcaption>
                    <p class="highlight-caption">Come hear Agent sticks tips for finding the best Sales</p>
                    <button type="button" class="btn btn-dark">Agent Jason Stick’s Tips</button>
                </figcaption>
                </div>
            </figure>
            <figure class="card">
                <div class="card-body"> 
                <img src="../assets/Stix-Late.png" class="highlight" alt="Logan Stix"/>
                <figcaption>
                    <p class="highlight-caption">Keep things light in the office with Logan Stix’ tips</p>
                    <button type="button" class="btn btn-dark">Logan Stix's Tips</button>
                </figcaption>
                </div>
            </figure>
            <figure class="card">
                <div class="card-body">
                <img src="../assets/CoachGetOutThere1.png" class="highlight" alt="Coach Stickman"/>
                <figcaption>
                    <p class="highlight-caption">Come get some coaching advice from Coach Stick!</p>
                    <button type="button" class="btn btn-dark">Coach Stickman's Advice</button>
                </figcaption>
                </div>
            </figure>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.blog-highlights {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 8px;
}
.highlight {
    width: 300px;
    height: 300px;
}
.highlight-caption {
    text-align: center;
    gap: 10px;
}
p {
    padding: 0px 16px;
    width: 300px;
}
button {
    color: #009df8;
    background-color: #212529;
}

</style>